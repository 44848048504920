<template>
  <div class="page">
    <Header :options="{ remove: ['user'] }">
      <template v-slot:user>
        <a class="avatar close-btn" @click="goback">
          <figure><iconic name="times" /></figure>
        </a>
      </template>
    </Header>
    <div class="page-container" v-if="pageContent">
      <div class="page-title" v-if="pageTitle">
        <div class="page-title-content">
          <h1 uppercase>{{ pageTitle }}</h1>
        </div>
      </div>
      <div class="page-main">
        <div class="page-content" v-html="`${pageContent}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/home/Header.vue";
export default {
  components: { Header },
  data: function () {
    return {
      route: null,
      pageTitle: null,
      pageContent: null,
    };
  },
  methods: {
    goback: function () {
      this.$router.go(-1);
    },
    getPageContent: async function () {
      this.ialert.go({ type: "loader" });
      const sedData = this.$api.get(`${process.env.VUE_APP_API_HOST}?view=${this.route}`);
      sedData.then((response) => this.pageLoaded(response));
      sedData.catch((response) => this.pageError(response));
    },
    pageLoaded: function (response) {
      this.pageTitle = response.data?.language[`page-${this.route}-title`];
      this.pageContent = this.$global.useSemantic(response.data?.language[`page-${this.route}`]);
      this.ialert.close();
    },
    pageError: function (response) {
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
  },
  mounted: async function () {
    this.route = this.$global.removeCharAt(1, this.$route.path);
    this.getPageContent();
  },
};
</script>

<style lang="scss">
.page {
  background: $white;
  &-title {
    position: sticky;
    top: $header_height;
    background: $white;
    &-content {
      max-width: $content_width;
      margin: 0 auto;
      padding: 0 $mpadding;
      h1 {
        padding: $mpadding 0;
        border-bottom: solid 1px $alto;
      }
    }
  }
  &-main {
    max-width: $content_width;
    margin: 0 auto;
  }
  &-content {
    display: inline-block;
    padding: $mpadding;
    background: #fff;
    word-break: break-word;
  }
  .main-header {
    &-container {
      padding: 0 $mpadding;
    }
    .close-btn {
      figure {
        margin-right: 0;
      }
    }
  }
}
</style>
